import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar() {
  const navigate = useNavigate()
  const [isDropdownOpen, setIsDropdownOpen] = useState([false,false,false,false,false]);

  const toggleDropdown = (index, entering) => {
    setIsDropdownOpen(prevState => {
      const newState = [false,false,false,false,false];
      if (entering) {
        newState[index] = true; 
      }else {
        newState[index] = false; 
      }
      return newState;
    });
  };
  
  const handleLinkClick = (index, path) => {
    // Close the dropdown
    setIsDropdownOpen(prevState => {
      
      const newState = [...prevState];
      newState[index] = false; 
      return newState;
    });
    
    // Navigate to the specified path
    navigate(path);
  };

  const DropdownLink = ({ href, index, page, text }) => {
    return (
      <a
        href={href}
        className="block px-3 py-1 hover:bg-highlight"
        onClick={() => handleLinkClick(index, page)}
      >
        {text}
      </a>
    );
  };

    return (
      <header className=" bg-black backdrop-filter backdrop-blur-md  bg-opacity-50 md:sticky top-0 z-10 outline-dark select-none">
          
          <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
              
            <nav className="md:mr-auto md:ml-4 md:pl-4 py-1 text-gray-300	flex flex-wrap items-center text-lg justify-center">
            <p className=" title-font mb-0 ml-3 text-xl font-bold text-highlight">
                Andrew Thompson&nbsp;&nbsp;
              </p>
              <Link to="/" className="pr-5 pl-4 font-semibold hover:text-highlight transition-colors duration-300 underline-effect">
                Home
              </Link>
              <Link to="/Blog" className="pr-5 font-semibold border-l border-highlight-brown pl-4  hover:text-highlight transition-colors duration-300 underline-effect">
                Blog
              </Link>
              <div className="underline-effect  hover:text-highlight transition-colors duration-300">
              <a href="/Resume.pdf" target="_blank" rel="noopener noreferrer"
                className="font-semibold border-l border-highlight-brown pl-4 flex items-center resume ">
                  Resume
                  <img src="/arrow_out.svg" alt="a_out" className="h-3 w-3 ml-1 mr-3 stroke-icon"/>
                </a>
              </div>
              
              
              <div className="relative"
              onMouseEnter={() => toggleDropdown(0, true)}
              onMouseLeave={() => toggleDropdown(0, false)}>
              <Link to="/Projects" className="pr-5 font-semibold border-l mb-2 border-highlight-brown pl-4  hover:text-highlight transition-colors duration-300 underline-effect">
                Projects
              </Link>
              {(isDropdownOpen[0] === true) && (
              <div className="absolute top-full left-0 bg-black backdrop-filter backdrop-blur-md  bg-opacity-50 text-sm outline-black text-white shadow-lg rounded-lg overflow-hidden z-20 w-32">
                <DropdownLink href="#twhg" text="The World's Hardest Game" index={0} page="/Projects"/>
                <DropdownLink href="#rcr" text="River City Reborn" index={0} page="/Projects"  />
                <DropdownLink href="#morcc" text="Morse Code Compiler" index={0} page="/Projects"  />
                <DropdownLink href="#boiler-living" text="Boiler Living" index={0} page="/Projects"  />
                <DropdownLink href="#boiler-block" text="Boiler Block" index={0} page="/Projects"  />
                <DropdownLink href="#descargo" text="Descargo" index={0} page="/Projects"  />
              </div>
            )}
          </div>
              <div className="relative"
              onMouseEnter={() => toggleDropdown(1, true)}
              onMouseLeave={() => toggleDropdown(1, false)}>
              <Link
              to="/Misc"
              className="font-semibold border-l border-highlight-brown pl-4 pr-5 mb-2 hover:text-highlight transition-colors duration-300 underline-effect"
              >
              Misc
            </Link>
              {(isDropdownOpen[1] === true) && (
              <div className="absolute top-full left-0 bg-black backdrop-filter backdrop-blur-md  bg-opacity-50 text-sm outline-black text-white shadow-lg rounded-lg overflow-hidden z-20 w-32">
                <DropdownLink href="#sequence" text="Sequence Memorization" index={1} page="/Misc"/>
                <DropdownLink href="#brgt-website" text="BRGT Website" index={1} page="/Misc"/>
                <DropdownLink href="#print-manager" text="Print Manager" index={1} page="/Misc"/>
                <DropdownLink href="#virtual-marketplace" text="Virtual Marketplace" index={1} page="/Misc"  />
              </div>
            )}
          </div>
            

            <div className="relative"
            onMouseEnter={() => toggleDropdown(2, true)}
            onMouseLeave={() => toggleDropdown(2, false)}>
            <Link to="/CGT270" className="pr-5 font-semibold border-l mb-2 border-highlight-brown pl-4  hover:text-highlight transition-colors duration-300 underline-effect">
              CGT270
            </Link>
            {(isDropdownOpen[2] === true) && (
            <div className="absolute top-full left-0 bg-black backdrop-filter backdrop-blur-md  bg-opacity-50 text-sm outline-black text-white shadow-lg rounded-lg overflow-hidden z-20 w-32">
              <DropdownLink href="#irish-language" text="Irish Language Analysis" index={2} page="/CGT270"/>
              <DropdownLink href="#decoding-purdue" text="Decoding Purdue" index={2} page="/CGT270"/>
              <DropdownLink href="#makeover-monday" text="Makeover Monday" index={2} page="/CGT270"/>
            </div>
          )}
        </div>


              <div className="relative"
              onMouseEnter={() => toggleDropdown(3, true)}
              onMouseLeave={() => toggleDropdown(3, false)}>
              <Link to="/Robotics" className="pr-5 font-semibold border-l mb-2 border-highlight-brown pl-4  hover:text-highlight transition-colors duration-300 underline-effect">
                Robotics
              </Link>
              {(isDropdownOpen[3] === true) && (
              <div className="absolute top-full left-0 bg-black backdrop-filter backdrop-blur-md  bg-opacity-50 text-sm outline-black text-white shadow-lg rounded-lg overflow-hidden z-20 w-32">
                <DropdownLink href="#arobo7" text="AROBO7" index={3} page="/Robotics"/>
                <DropdownLink href="#2630a" text="2630A TiP" index={3} page="/Robotics"/>
              </div>
            )}

          </div>

                <div className="relative"
              onMouseEnter={() => toggleDropdown(4, true)}
              onMouseLeave={() => toggleDropdown(4, false)}>
              <Link to="/Hextraction" className="pr-5 font-semibold border-l mb-2 border-highlight-brown pl-4  hover:text-highlight transition-colors duration-300 underline-effect">
                Hextraction
              </Link>
              {isDropdownOpen[4] && (
              <div className="absolute top-full left-0 bg-black backdrop-filter backdrop-blur-md  bg-opacity-50 text-sm outline-black text-white shadow-lg rounded-lg overflow-hidden z-20 w-32">
                <DropdownLink href="#custom-boards" text="Custom Boards" index={4} page="/Hextraction"/>
                <DropdownLink href="#custom-tiles" text="Custom Tiles" index={4} page="/Hextraction"/>
              </div>
            )}

          </div>

            </nav>
          </div>
          
        </header>
    );
}
